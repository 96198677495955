import { Button, Drawer, Space, Steps } from "antd";

import Logo from "./Logo";
import { differenceBy } from "lodash";
import Searchbar from "./Searchbar";
import { useSelector } from "react-redux";
import { useState, useEffect, useCallback, useMemo } from "react";
import ModulesDetail from "./ModulesDetail";
import PaymentOption from "./PaymentOptions";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { Spin } from "antd";
import { useDebounce, useShallowEqualSelector } from "../../hooks";
import {
  system_modules,
  selected_modules_id,
  selected_channels,
  current_business_id,
  sel_durations,
  filter_text,
  subscription_details,
} from "../../selectors/userSelectors";
import {
  useGetSystemModules,
  useGetClientSubscriptionDetails,
} from "../../redux/hooks/usershookActions";
import Modal from "../UI/Modal";
import { subscribleClient } from "../../redux/users/userActions";
import { notificationError, notificationSuccess } from "../util/helpers";
import { user_spinner } from "../../selectors/userSelectors";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import SystemModule from "../UI/SystemModule";

const steps = [
  {
    title: "My Selections",
    content: <ModulesDetail />,
  },
  {
    title: "Select Payment Option",
    content: <PaymentOption />,
  },
];

function Modules() {
  const [all_system_modules, setAllSystemModules] = useState([]);
  const [openSelections, setOpenSelections] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [sub_enabled, subSetEnabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [current, setCurrent] = useState(0);
  const [btnHighlight, setBtnHighlight] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = queryString.parse(useLocation().search);

  useGetSystemModules(enabled, setEnabled);
  useGetClientSubscriptionDetails(sub_enabled, subSetEnabled, params.bId);
  const all_system_modules_ = useShallowEqualSelector(system_modules);
  const spinner = useShallowEqualSelector(user_spinner);
  const business_id = useShallowEqualSelector(current_business_id);
  const modules = useShallowEqualSelector(selected_modules_id);
  const channel = useShallowEqualSelector(selected_channels);
  const duration = useShallowEqualSelector(sel_durations);
  const filter_text_ = useShallowEqualSelector(filter_text);
  const subscriptionDetails = useShallowEqualSelector(subscription_details);

  useDebounce(() => filterModule(filter_text_), 1000, [filter_text_]);

  const subscribedModules = useMemo(() => {
    return (
      subscriptionDetails?.modules &&
      subscriptionDetails.modules.map((module) => module.module)
    );
  }, [subscriptionDetails]);

  const modulesToDisplay = useMemo(
    () => differenceBy(all_system_modules_, subscribedModules, "id"),
    [all_system_modules_, subscribedModules]
  );

  // set all system modules from global to local state
  useEffect(() => {
    setAllSystemModules(modulesToDisplay);
  }, [modulesToDisplay]);

  function filterModule(text) {
    const filtered =
      all_system_modules &&
      all_system_modules.filter((module) => {
        return module.name.toLowerCase().includes(text.toLowerCase());
      });

    if (text === "") {
      setAllSystemModules(modulesToDisplay);
    } else if (filtered.length) {
      setAllSystemModules(filtered);
    } else {
      // to be set to no content later
      setAllSystemModules([]);
    }
  }

  function handleSubscribe() {
    const creds = {
      business_id,
      modules,
      channel,
      duration,
    };

    subscribleClient(dispatch, creds).then((res) => {
      if (res.status === "success") {
        notificationSuccess(res.message);
        navigate(`/invoice/?bId=${business_id}`);
        // window.location.replace(res.data.authorization_url);
      } else if (res.status === "error") {
        notificationError(res.message);
      }
    });
  }

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const showDrawer = useCallback(() => {
    setOpenSelections(true);
  }, []);

  const closeDrawer = () => {
    setOpenSelections(false);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const isMobile = window.innerWidth < 500;

  let content =
    all_system_modules?.length &&
    all_system_modules
      .sort((a, b) => (a.flag != null && b.flag == null ? -1 : 1))
      .map((module) => (
        <SystemModule
          key={module.id}
          id={module.id}
          name={module.name}
          image={module.image}
          badge={module.short_name}
          price={module.pricing}
          desc={module.description}
          currency={module.currency}
          symbol={module.symbol}
          flag={module.flag}
          to_cart={params.short_name === module.short_name}
          // no_of_users={module.no_of_users}
        />
      ));

  const selectedModule = useSelector((state) => state.modules.modules);

  useEffect(() => {
    if (selectedModule.length === 0) return;
    setBtnHighlight(true);
    const timer = setTimeout(() => {
      setBtnHighlight(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedModule.length]);

  const cartIconClasses = `cart h2 ${btnHighlight ? "bump" : ""}`;

  const handleClick = () => {
    closeDrawer();
    setShowModal(true);
  };

  return (
    <div className="container-scroller">
      <div className="row">
        <div className="col-12 col-md-9 px-0 bg-light scroll-section mx-auto">
          <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
            <div className="content-wrapper d-flex flex-column px-4 px-lg-5 py-4 py-lg-5">
              <div className="top d-flex flex-column justify-content-between px-2">
                <div className="logo-container d-flex justify-content-between w-100">
                  <Logo />
                  <button className={cartIconClasses} onClick={showDrawer}>
                    <MdOutlineAddShoppingCart />{" "}
                    <span>{selectedModule.length}</span>
                  </button>
                </div>
                <p className="my-1">Modules {">"} Choose Portfolio</p>
                <Searchbar placeholder={"Search module: Shift Management"} />
                {all_system_modules?.length === 0 && filter_text_ !== "" ? (
                  <p className="lead">
                    <i>We could not find what you are looking for.</i>{" "}
                  </p>
                ) : null}
              </div>
              <div className="container-fluid h-100 d-flex align-items-lg-center mt-4 mt-lg-0 px-0">
                <div className="row px-sm-0 w-100 mx-auto">
                  <div className="col-12 mb-lg-0">
                    <div className="row px-md-0 mt-3 modules">
                      {all_system_modules?.length
                        ? content
                        : filter_text_ === "" && (
                            <div className="d-flex justify-content-center align-items-center">
                              <Spin size="large" />
                            </div>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openSelections && (
          <Drawer
            title=""
            placement="right"
            width={isMobile ? "100%" : 500}
            onClose={closeDrawer}
            open={showDrawer}
            style={{ paddingTop: 20 }}
            extra={
              <Space>
                <Button onClick={closeDrawer} className="myclosebtn">
                  Close
                </Button>
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    className="mybtn"
                    onClick={() => next()}
                  >
                    Proceed
                  </Button>
                )}
                {current > 0 && (
                  <Button
                    className="mybtn"
                    style={{
                      margin: "0 8px",
                    }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    className="mybtn"
                    type="primary"
                    onClick={handleClick}
                    loading={spinner}
                  >
                    Proceed
                  </Button>
                )}
              </Space>
            }
          >
            <Steps current={current} items={items} />
            <div className="steps-content">{steps[current].content}</div>
          </Drawer>
        )}
        {showModal && (
          <Modal
            className="confirmationModal"
            onClose={() => setShowModal(false)}
          >
            <button
              className="confirmationCancel"
              onClick={() => setShowModal(false)}
            >
              &#10005;
            </button>
            <h3>Terms & Conditions</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro
              consequuntur dolores dicta aperiam tempora. Eaque, itaque dolor,
              eligendi natus earum incidunt architecto neque autem sunt esse hic
              reprehenderit totam suscipit sequi excepturi illo voluptatum?
              Delectus reiciendis quam perspiciatis reprehenderit dolorum earum
              molestias soluta mollitia, libero rem! Est iure quia laudantium.
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Exercitationem, distinctio. Dolorem quibusdam explicabo eius!
              Saepe voluptate corrupti eius impedit et! Sit, cupiditate?
              Obcaecati adipisci quod sequi! Sapiente perferendis nesciunt dicta
              dolorem qui deserunt amet nam. Illum nesciunt possimus cupiditate
              sint. Molestiae corporis sint, reiciendis eum expedita commodi
              nobis reprehenderit officia cupiditate dolorum rem deleniti
              deserunt omnis amet magni nihil enim inventore. Eligendi nostrum
              quae ullam doloremque sunt earum sint maiores eius sed impedit?
              Aperiam ex id aliquid cum nam minus, incidunt vel. Tenetur
              excepturi explicabo nesciunt aspernatur quaerat illum, provident
              minima atque culpa. Quasi aliquid necessitatibus qui nam dicta
              perferendis.
            </p>
            <div className="my-form-control">
              <input
                type="checkbox"
                name="terms_and_conditions"
                onChange={handleChecked}
                checked={checked}
              />
              <label htmlFor="terms_and_conditions" onClick={handleChecked}>
                I have read and agreed to the terms and conditions
              </label>
            </div>
            <Button
              size="large"
              className="mybtn"
              onClick={handleSubscribe}
              disabled={!checked}
              loading={spinner}
            >
              Accept
            </Button>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default Modules;
